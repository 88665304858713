/**
 * Equipment list for recipe pages
 */

// Import statements
import * as React from "react"
import "../../styles/print.scss"

/**
 * Define the PrintButton constant
 * @param recipe
 * @returns {JSX.Element}
 * @constructor
 */
const PrintButton = ({recipe}) => {

    /**
     * Function to print the page
     * @param strikeState
     * @param index
     */
    function printDetailCard() {

        // needed if SSR
        if (typeof window != "undefined" && typeof document != "undefined") {

            // print recipe card
            window.print_this = () => {
                let id = 'recipe-detail-card';
                var prtContent = document.getElementById(id);
                var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write('<style>' + css + '</style>');
                WinPrint.document.write(prtContent.innerHTML);
                WinPrint.document.close();
                WinPrint.setTimeout(function () {
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            }
        }
    }

    // return the information
    return (
        <button
            className="btn btn-tertiary btn-sm mb-1"
            id="print_recipe_card"
            aria-label="Print recept"
            onClick={() => {
                printDetailCard()
            }}
        >
            Print recept
        </button>
    )
}

/**
 * Export the PrintButton variable
 */
export default PrintButton

/**
 * Define the CSS for the print page
 */
const css = `

/* hide specific information */
.gatsby-image-wrapper,
button#print_recipe_card,
.scale,
#yield:not(.active),
img {
  display: none;
}

/* make sure buttons look well */
button {
    display: block;
    background: none;
    border: none;
    padding: 0px;
    margin: 0 0 5px 0;
    text-align: left;
}

a {
  color: #000;
}

#recipe-details > div:first-child ul.ingredients {
  padding: 0 0 5px 0;
}
#recipe-details > div:first-child ul.ingredients li {
  display: block;
  font-weight: bold;
}
#recipe-details > div:first-child ul.ingredients li time {
  font-weight: normal;
  margin-left: 15px;
  float: right;
}
#recipe-details > div:nth-child(2) {
  border-top: 1px solid #ccc;
}
#recipe-details > div:nth-child(2) .details_wrapper {
  display: table;
  width: 100%;
  padding: 0 7.5px 7.5px 7.5px;
  border-bottom: 1px solid #ccc;
}
#recipe-details > div:nth-child(2) .details_wrapper ul {
  font-size: 0.875rem;
  list-style-type: none;
  text-align: right;
}
#recipe-details > div:nth-child(2) .details_wrapper ul li {
  display: inline-block;
  margin: 0 7.5px 0 0;
}
#recipe-details > div:nth-child(2) .details_wrapper ul li a span.icon::before {
  color: #0a4237;
}
#recipe-details > div:nth-child(2) .details_wrapper .details_wrapper_left {
  display: table-cell;
  width: 20%;
  font-weight: bold;
}
#recipe-details > div:nth-child(2) .details_wrapper .details_wrapper_right {
  display: table-cell;
  width: 80%;
}
#recipe-details > div:nth-child(2) .cuisines ul,
#recipe-details > div:nth-child(2) .courses ul,
#recipe-details > div:nth-child(2) .diets ul {
  padding: 0 7.5px 7.5px 7.5px;
  list-style-type: none;
}
#recipe-details > div:nth-child(2) .cuisines ul li,
#recipe-details > div:nth-child(2) .courses ul li,
#recipe-details > div:nth-child(2) .diets ul li {
  display: inline-block;
  margin: 0 7.5px 0 0;
}

/* prep time, cooking time, total time */
ul.ingredients {
  margin: 0 0 5px 0;
  padding: 0;
  clear: both;
}
ul.ingredients li {
  display: inline;
  margin: 0 5px 0 0;
}
ul.ingredients li span {
  text-align: left;
  color: #000;
  margin-left: 20px;
}
ul.ingredients li:not(:first-child) {
  list-style-type: none;
}

`