/**
 * Related Recipes for in the sidebar
 */

// Import statements
import * as React from "react"

/**
 * Define the RelatedRecipesLinksWrapper constant
 * @returns {JSX.Element}
 * @constructor
 */
const RelatedRecipesLinksWrapper = () => {

    // return the information
    return (
        <div id="related_recipes_links_wrapper">
            <span className="placeholder col-12 bg-primary"></span>
        </div>
    )
}

/**
 * Export the RelatedRecipesLinksWrapper constant
 */
export default RelatedRecipesLinksWrapper
