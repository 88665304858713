/**
 * Instructions list for recipe pages
 */

// Import statements
import * as React from "react"
import {useState} from "react"

/**
 * Define the Instructions constant
 * @param recipe
 * @returns {JSX.Element}
 * @constructor
 */
const Instructions = ({recipe}) => {

    // ----------------------
    // BUILD TIME DATA FETCHING USING GRAPHQL
    // ----------------------


    // ----------------------
    // RUNTIME DATA FETCHING
    // ----------------------

    // get the instructions array
    if (recipe.instructionsWysiwyg !== null) {
        recipe.instructionsWysiwyg = recipe.instructionsWysiwyg.replace(new RegExp(/(<img\b\s+.*?class=")(.*?)(">)/, "g"), 'img-fluid')
    }

    // define empty array
    let instructions_li_elements = [];

    // needed if SSR
    if (typeof document != "undefined") {

        // get instructions array
        let instructions_elements = document.createRange().createContextualFragment(recipe.instructionsWysiwyg);
        instructions_li_elements = instructions_elements.querySelectorAll('li')
    }


    // make the instructions strikeable
    const [strikeState, setStrikeState] = useState([])

    /**
     * Function to strike through list items
     * @param strikeState
     * @param index
     */
    function addStrikeState(strikeState, index) {

        // define starting array
        let array = [];
        if (strikeState.length > 0) {
            array = strikeState;
        }

        // remove item from array when it already exists
        if (!array.includes(index)) {
            setStrikeState(array => [...array, index]);

            // add it to the array
        } else {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === index) {
                    array.splice(i, 1);
                }
            }
            setStrikeState(array => [...array]);
        }
    }

    // return the information
    return (
        <div className="row">
            <div className="col-12 order-4" id="instructions">
                <h3>Instructies</h3>
                {[...instructions_li_elements].map((element, index) => {
                    return (
                        <button key={`instructions-button-${index}`} onClick={() => {
                            addStrikeState(strikeState, index)
                        }} className={strikeState.includes(index) ? "active" : ""}>
                            {element.innerText ?? ''}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

/**
 * Export the ingredients variable
 */
export default Instructions