/**
 * Single page for recipes
 */

// Import statements
import React from "react"
import {graphql, Link} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import parse from "html-react-parser"
import "../styles/files/single-recipe.scss"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
//import "../styles/@wordpress/block-library/build-style/style.styles"
//import "../styles/@wordpress/block-library/build-style/theme.styles"
import Layout from "../components/layout"
import Body from "../components/body"
import Seo from "../components/common/seo"
import AdFooter from "../components/ad/ad-footer";
import AdSkyscraper from "../components/ad/ad-skyscraper";
import RelatedRecipesLinksWrapper from "../components/recipe/related-recipes-links-wrapper";
import RelatedRecipesLinks from "../components/recipe/related-recipes-links";
import NutritionTable from "../components/recipe/nutritiontable";
import InstagramFeed from "../components/common/instagram-feed";
import SocialMedia from "../components/common/socialmedia";
import RecipeRating from "../components/common/rating";
import Author from "../components/common/author";
import BlogComments from "../components/comment/comments";
import {convertTimeToFullDescription, formatDate} from "../components/common/format-date"
import Ingredients from "../components/recipe/ingredients";
import Equipment from "../components/recipe/equipment";
import Instructions from "../components/recipe/instructions";
import PrintButton from "../components/recipe/print";

/**
 * Define the RecipePostTemplate constant
 * @param previous
 * @param next
 * @param recipe
 * @param comments
 * @param advertorials
 * @returns {JSX.Element}
 * @constructor
 */
const RecipePostTemplate = ({data: {previous, next, recipe, comments, advertorials}}) => {

    // define the featuredImage of this post
    const featuredImage = {
        data: recipe.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
        alt: recipe.featuredImage?.node?.alt || ``,
    }

    // get the publication date
    const publication_date = new Date(recipe.date);
    //publication_date.toLocaleDateString('nl-NL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

    // return the information
    return (
        <Layout isRecipePage advertorials={advertorials}>
            <Body bodyClass="single single-recipe"/>
            <Seo title={recipe.title} description={recipe.excerpt}/>

            <article className="recipe">

                <header className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1">
                        <h1 className="entry-title" itemProp="name">{parse(recipe.title)}</h1>
                        <ul className="list-inline uppercase">
                            <li key="publication_date">{formatDate(publication_date)}</li>
                            <li key="author">Door {recipe.author.node.uri && (
                                <Link to={recipe.author.node.uri}>{recipe.author.node.name}</Link>
                            )}</li>
                            <li key="recipes">{recipe.recipeCuisines.nodes.map((cuisine, index) => {
                                return (
                                    <Link to={cuisine.uri} key={cuisine.uri}>{cuisine.name}</Link>
                                )
                            })}</li>
                        </ul>

                        <ul className="list-inline uppercase">
                            <li key="servings">Voor {recipe.servesText} {recipe.servesText === 1 ? "persoon" : "personen"}</li>
                        </ul>

                        <ul className="list-inline uppercase">

                            {recipe.preparationText !== '00:00' && (
                                <li className="preparation_time" key="preparation_time">
                                    VoorbereidingRatingstijd
                                    <span
                                        className="stats-space">{recipe.preparationText !== null && convertTimeToFullDescription(recipe.preparationText.split(':'))}</span>
                                </li>
                            )}

                            {recipe.cookText !== '00:00' && (
                                <li className="cook_time" key="cook_time">
                                    Kooktijd
                                    <span
                                        className="stats-space">{recipe.cookText !== null && convertTimeToFullDescription(recipe.cookText.split(':'))}</span>
                                </li>
                            )}

                            {recipe.totalText !== '00:00' && (
                                <li className="total_time" key="total_time">
                                    Totale benodigde tijd
                                    <span
                                        className="stats-space">{recipe.totalText !== null && convertTimeToFullDescription(recipe.totalText.split(':'))}</span>
                                </li>
                            )}
                        </ul>

                        {recipe.recipeCuisines.nodes.map((cuisine, index) => {
                            if (index > 1) {
                                return false;
                            }
                            return (
                                <Link to={cuisine.uri} className="btn btn-outline-primary btn-sm" id="back_to_category"
                                      key={`back-to-category-a-${index}`}>
                                    <span className="icon icon-angle-left"></span>Terug naar categorie
                                </Link>
                            )
                        })}

                        <a href="#recipe-detail-card" className="btn btn-outline-primary btn-sm" id="jump_to_recipe"
                           key="jump_to_recipe">
                            <span className="icon icon-angle-down"></span>Ga naar recept
                        </a>
                    </div>
                </header>

                <div className="row" id="header-single-image">
                    <nav className="d-flex justify-content-between">
                        <ul className="pager">
                            {previous && (
                                <li className="pager-button btn btn-grey previous">
                                    <Link to={previous.uri} rel="prev" className="tip tip-right"
                                          data-title={parse(previous.title)}>
                                        ← vorige
                                    </Link>
                                </li>
                            )}
                            {next && (
                                <li className="pager-button btn btn-grey next">
                                    <Link to={next.uri} rel="next" className="tip tip-left"
                                          data-title={parse(next.title)}>
                                        volgende →
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>
                    <div className="col-md-10 offset-md-1">
                        {/* if we have a featured image for this recipe let's display it */}
                        {featuredImage?.data && (
                            <GatsbyImage
                                image={featuredImage.data}
                                alt={featuredImage.alt}
                            />
                        )}
                    </div>
                </div>

                <div className="row single-content" id="recipe-content">
                    <div className="col-md-8 col-lg-7 offset-lg-1 order-1 col-no-padding" id="recipe-content-body">

                        <AdFooter/>

                        <div id="recipe-blog-text">
                            <div id="result"></div>
                            {!!recipe.introWysiwyg && parse(recipe.introWysiwyg)}
                        </div>
                        <div id="recipe-detail-card">
                            <div id="recipe-detail-header" className="row">
                                <div className="col-sm-4">
                                    {featuredImage?.data && (
                                        <GatsbyImage
                                            image={featuredImage.data}
                                            alt={featuredImage.alt}
                                        />
                                    )}
                                </div>
                                <div className="col-sm-8">
                                    <h2>{parse(recipe.title)}</h2>
                                    <PrintButton recipe={recipe}/>
                                </div>
                            </div>
                            <div id="recipe-details" className="row">
                                <div className="col-sm-6">
                                    <ul className="ingredients">
                                        <li className="preparation_time" key="preparation_time">
                                            Voorbereidingstijd
                                            <span
                                                className="stats-space">{recipe.preparationText !== null && parse(recipe.preparationText)}</span>
                                        </li>

                                        <li className="cook_time" key="cook_time">
                                            Kooktijd
                                            <span
                                                className="stats-space">{recipe.cookText !== null && parse(recipe.cookText)}</span>
                                        </li>

                                        <li className="total_time" key="total_time">
                                            Totale tijd
                                            <span
                                                className="stats-space">{recipe.totalText !== null && parse(recipe.totalText)}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">

                                </div>
                            </div>
                            <div id="tick_wrapper" className="row">
                                <Ingredients recipe={recipe}/>
                                <Equipment recipe={recipe}/>
                                <Instructions recipe={recipe}/>
                            </div>
                        </div>
                        <div id="recipe-footer">
                            <RecipeRating recipe={recipe}/>
                            <div id="author-single-meta">
                                <Author author={recipe.author.node}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 order-2" id="sidebar-right">
                        <AdSkyscraper/>
                        <RelatedRecipesLinksWrapper/>
                        <RelatedRecipesLinks title="Ook leuk"/>
                        <NutritionTable recipe={recipe}/>
                        <InstagramFeed/>
                        <SocialMedia title={recipe.title} featuredImage={featuredImage}/>

                    </div>
                </div>

                <BlogComments post={recipe} comments={comments}/>

                {!!recipe.content && (
                    <section itemProp="articleBody">{parse(recipe.content)}</section>
                )}

            </article>

        </Layout>
    )
}

/**
 * Export the RecipePostTemplate variable
 */
export default RecipePostTemplate

/**
 * Get the single recipe with GraphQL
 * @type {StaticQueryDocument}
 */
export const pageQuery = graphql`
  query RecipePostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    recipe: wpRecipe(id: { eq: $id }) {
        id
        content
        title
        slug
        date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
        calFromCarbohydratesText
        calFromFatText
        calFromProteinText
        calTotalText
        calciumRdiText
        calciumText
        carbsTotalRdiText
        carbsTotalText
        cautionsTextareasmall
        cholesterolRdiText
        cholesterolText
        cookText
        databaseId
        dietLabelsTextarea
        equipmentWysiwyg
        fatMonounsaturatedRdiText
        fatMonounsaturatedText
        fatPolyunsaturatedRdiText
        fatSaturatedRdiText
        fatPolyunsaturatedText
        fatSaturatedText
        fatTotalRdiText
        fatTransText
        fatTotalText
        fatTransRdiText
        fiberRdiText
        fiberText
        folateEquivalentRdiText
        folateEquivalentText
        folateFoodRdiText
        folateFoodText
        folicAcidRdiText
        folicAcidText
        healthLabelsTextarea
        ingredientsTextarea
        instructionsWysiwyg
        introWysiwyg
        ironRdiText
        ironText
        magnesiumRdiText
        magnesiumText
        niacinB3RdiText
        niacinB3Text
        phosphorusRdiText
        phosphorusText
        potassiumRdiText
        potassiumText
        proteinTotalRdiText
        preparationText
        proteinTotalText
        ratingsAverage
        ratingsScore
        ratingsUsers
        riboflavinB2RdiText
        riboflavinB2Text
        servesText
        sodiumRdiText
        sodiumText
        spotlightAdvertorial
        spotlightHomepage
        sugarsAddedRdiText
        sugarsAddedText
        sugarsTotalRdiText
        sugarsTotalText
        totalText
        thiaminB1RdiText
        thiaminB1Text
        vitaminARdiText
        vitaminAText
        vitaminB12RdiText
        vitaminB12Text
        vitaminB6RdiText
        vitaminB6Text
        vitaminCRdiText
        vitaminCText
        vitaminDRdiText
        vitaminDText
        vitaminERdiText
        vitaminEText
        vitaminKRdiText
        vitaminKText
        waterRdiText
        waterText
        zincRdiText
        zincText
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 50
                  placeholder: DOMINANT_COLOR
                  layout: FULL_WIDTH
                  breakpoints: [546,605,805,955,1105]
                )
              }
            }
          }
        }
        author {
          node {
            databaseId
            name
            uri
            description
            nicename
            email
            slug
            avatar {
              height
              size
              url
              width
            }
          }
        }
        recipeCollections {
          nodes {
            name
            uri
          }
        }
        recipeCourses {
          nodes {
            name
            uri
          }
        }
        recipeCuisines {
          nodes {
            uri
            name
          }
        }
        recipeSeasons {
          nodes {
            name
            uri
          }
        }
        recipeSpecialDiets {
          nodes {
            name
            uri
          }
        }
    }
    advertorials: allWpRecipe(
      filter: {spotlightAdvertorial: {eq: "on"}}
      sort: {order: DESC, fields: date}
      limit: 4
    ) {
      nodes {
        excerpt: content
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        content
        featuredImage { ...cardImageFields }
      }
    }
    comments: allWpComment(filter: {commentedOn: {node: {id: { eq: $id } } } } ) {
      edges {
        node {
          id
          databaseId
          author {
            node {
              id
              name
              email
              url
              databaseId
            }
          }
          date(formatString: "YYYY-MM-DDTHH:mm:ss.sssZ")
          content
          parentDatabaseId
        }
      }
    }
    previous: wpRecipe(id: { eq: $previousPostId }) {
        uri
        title
    }
    next: wpRecipe(id: { eq: $nextPostId }) {
        uri
        title
    }
  }
`
