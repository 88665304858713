/**
 * Equipment list for recipe pages
 */

// Import statements
import * as React from "react"
import {useState} from "react"

/**
 * Define the Equipment constant
 * @param recipe
 * @returns {JSX.Element}
 * @constructor
 */
const Equipment = ({recipe}) => {

    // ----------------------
    // BUILD TIME DATA FETCHING USING GRAPHQL
    // ----------------------


    // ----------------------
    // RUNTIME DATA FETCHING
    // ----------------------

    // define empty array
    let equipment_li_elements = [];

    // needed if SSR
    if (typeof document != "undefined") {

        // get equipment array
        let equipment_elements = document.createRange().createContextualFragment(recipe.equipmentWysiwyg);
        equipment_li_elements = equipment_elements.querySelectorAll('li')

    }


    // make the equipment strikeable
    const [strikeState, setStrikeState] = useState([])

    /**
     * Function to strike through list items
     * @param strikeState
     * @param index
     */
    function addStrikeState(strikeState, index) {

        // define starting array
        let array = [];
        if (strikeState.length > 0) {
            array = strikeState;
        }

        // remove item from array when it already exists
        if (!array.includes(index)) {
            setStrikeState(array => [...array, index]);

            // add it to the array
        } else {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === index) {
                    array.splice(i, 1);
                }
            }
            setStrikeState(array => [...array]);
        }
    }

    // return the information
    return (
        <div className="row">
            <div className="col-12 order-3">
                <h3>Benodigdheden</h3>
                {[...equipment_li_elements].map((element, index) => {
                    return (
                        <button key={`equipment-button-${index}`} onClick={() => {
                            addStrikeState(strikeState, index)
                        }} className={strikeState.includes(index) ? "active" : ""}>
                            {element.innerText ?? ''}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

/**
 * Export the equipment variable
 */
export default Equipment