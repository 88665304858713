/**
 * Nutrition table for in the sidebar
 */

// Import statements
import * as React from "react"
import {usePost} from "../react/api";
import edamamlogo from "../../../content/images/badge.png"
import FormData from "form-data";

/**
 * Define the NutritionTable constant
 * @param recipe
 * @returns {JSX.Element}
 * @constructor
 */
const NutritionTable = ({recipe}) => {

    // ----------------------
    // BUILD TIME DATA FETCHING USING GRAPHQL
    // ----------------------


    // ----------------------
    // RUNTIME DATA FETCHING
    // ----------------------

    // define session id
    //const session_id = /SESS\w*ID=([^;]+)/i.test(document.cookie) ? RegExp.$1 : false;
    //const session_id = ( /SESS\w*ID=([^;]+)/i.test(document.cookie) && typeof document !== "undefined") ? RegExp.$1 : false;
    const session_id = false;

    // define data
    const formData = new FormData();
    formData.append('ajax', 'GetLoginState');
    formData.append('session_id', session_id);

    // get logged in state
    let getLoginState = usePost({url: 'https://account.staging.myfoodblog.nl/ajax/', formData: formData}).data;

    // return the information
    return (
        <div id="nutritionfacts">
            <section className="nutritionfacts table-responsive">
                <header className="nutritionfacts_header">
                    <h4>Voedingswaarde</h4>
                </header>

                <table
                    className={`nutritionfacts_table table table-borderless table-sm mb-0${getLoginState !== null && ['loggedout'].includes(getLoginState.state) ? ' free-access' : ''}`}>
                    <thead>
                    <tr>
                        <th colSpan="3" className="small">
                            Per portie
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th colSpan="2">
                            <b>Calorieën</b> {Math.round(recipe.calTotalText) ?? ''}
                        </th>
                        <td>Calorieën uit vetten
                            {Math.round(recipe.calFromFatText) ?? ''}
                        </td>
                    </tr>
                    <tr className="thick-row">
                        <td colSpan="3" className="small"><b>% aanbevolen dagelijkse hoeveelheid*</b></td>
                    </tr>
                    <tr>
                        <th colSpan="2">
                            <b>Vetten</b> {Math.round(recipe.calTotalText) ?? ''}g,
                            waarvan
                        </th>
                        <td className="bold">{typeof recipe.calTotalRdiText !== "undefined" ? Math.round(recipe.calTotalRdiText) + '%' : ''}</td>
                    </tr>
                    <tr>
                        <td className="blank-cell"></td>
                        <th>Verzadigde vetzuren {Math.round(recipe.fatSaturatedText) ?? ''}g
                        </th>
                        <td className="bold">{typeof recipe.fatSaturatedRdiText !== "undefined" ? Math.round(recipe.fatSaturatedRdiText) + '%' : ''}</td>
                    </tr>
                    <tr>
                        <td className="blank-cell"></td>
                        <th>Transvetzuren {Math.round(recipe.fatTransText) ?? ''}g
                        </th>
                        <td className="bold">{typeof recipe.fatTransRdiText !== "undefined" ? Math.round(recipe.fatTransRdiText) + '%' : ''}</td>
                    </tr>
                    <tr>
                        <td className="blank-cell"></td>
                        <th>Enkelvoudig onverzadigde vetzuren {Math.round(recipe.fatMonounsaturatedText) ?? ''}g
                        </th>
                        <td className="bold">{typeof recipe.fatMonounsaturatedRdiText !== "undefined" ? Math.round(recipe.fatMonounsaturatedRdiText) + '%' : ''}</td>
                    </tr>
                    <tr>
                        <td className="blank-cell"></td>
                        <th>Meervoudig overzadigde vetzuren {Math.round(recipe.fatPolyunsaturatedText) ?? ''}g
                        </th>
                        <td className="bold">{typeof recipe.fatPolyunsaturatedRdiText !== "undefined" ? Math.round(recipe.fatPolyunsaturatedRdiText) + '%' : ''}</td>
                    </tr>
                    </tbody>
                    {getLoginState !== null && ['free', 'subscribed'].includes(getLoginState.state) && (
                        <tbody>
                        <tr>
                            <th colSpan="2">
                                <b>Cholesterol</b> {Math.round(recipe.cholesterolText) ?? ''}mg
                            </th>
                            <td className="bold">{typeof recipe.cholesterolRdiText !== "undefined" ? Math.round(recipe.cholesterolRdiText) + '%' : ''}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">
                                <b>Zout</b> {Math.round(recipe.sodiumText) ?? ''}mg
                            </th>
                            <td className="bold">{typeof recipe.sodiumRdiText !== "undefined" ? Math.round(recipe.sodiumRdiText) + '%' : ''}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">
                                <b>Koolhydraten</b> {Math.round(recipe.carbsTotalText) ?? ''}g,
                                waarvan
                            </th>
                            <td className="bold">{typeof recipe.carbsTotalRdiText !== "undefined" ? Math.round(recipe.carbsTotalRdiText) + '%' : ''}</td>
                        </tr>
                        <tr>
                            <td className="blank-cell"></td>
                            <th>Vezels {Math.round(recipe.fiberText) ?? ''}g
                            </th>
                            <td className="bold">{typeof recipe.fiberRdiText !== "undefined" ? Math.round(recipe.fiberRdiText) + '%' : ''}</td>
                        </tr>
                        <tr>
                            <td className="blank-cell"></td>
                            <th>Suikers {Math.round(recipe.sugarsTotalText) ?? ''}g
                            </th>
                            <td className="bold">{typeof recipe.sugarsTotalRdiText !== "undefined" ? Math.round(recipe.sugarsTotalRdiText) + '%' : ''}</td>
                        </tr>
                        <tr>
                            <td className="blank-cell"></td>
                            <th>Toegevoegde suikers {Math.round(recipe.sugarsAddedText) ?? ''}g
                            </th>
                            <td className="bold">{typeof recipe.sugarsAddedRdiText !== "undefined" ? Math.round(recipe.sugarsAddedRdiText) + '%' : ''}</td>
                        </tr>

                        <tr className="thick-end">
                            <th colSpan="2">
                                <b>Eitwitten</b> {Math.round(recipe.proteinTotalText) ?? ''}g
                            </th>
                            <td>{typeof recipe.proteinTotalRdiText !== "undefined" ? Math.round(recipe.proteinTotalRdiText) + '%' : ''}</td>
                        </tr>
                        </tbody>
                    )}
                </table>

                {getLoginState !== null && ['subscribed'].includes(getLoginState.state) && (
                    vitamins()
                )}

                {getLoginState !== null && ['free'].includes(getLoginState.state) && (
                    <div className="alert mt-3">
                        <h4 className="free_access">Bekijk vitaminen en mineralen</h4>
                        <p className="small">Upgrade naar een Myfoodblog-pro account om van vele voordelen te genieten,
                            zoals een uitgebreide lijst van vitaminen en mineralen bij elk recept.</p>
                        <a href={process.env.GATSBY_ACCOUNT_URL} title="" className="btn btn-primary btn-sm">Upgraden
                            naar pro</a>
                    </div>
                )}

                {getLoginState !== null && ['loggedout'].includes(getLoginState.state) && (
                    <div className="alert mt-3">
                        <h4 className="free_access">Gratis nutritionele informatie</h4>
                        <p className="small">Maak een gratis account aan om te genieten van een advertentie-vrije
                            website, receptenverzamelingen te maken en nutritionele informatie bij elk recept te zien.
                            Helemaal gratis!</p>
                        <a href={process.env.GATSBY_ACCOUNT_URL} title="" className="btn btn-primary btn-sm">Registreer
                            om toegang te krijgen</a>
                    </div>
                )}

                {getLoginState !== null && ['subscribed'].includes(getLoginState.state) && (
                    <div>
                        <div id="collapse_button_wrapper" className="text-end">
                            <button className="btn btn-primary btn-sm" data-toggle="collapse"
                                    href="#collapseNutritionalExtra" aria-expanded="false"
                                    aria-controls="collapseNutritionalExtra">
                                <span className="icon icon-move"></span>
                            </button>
                        </div>
                        <div className="collapse" id="collapseNutritionalExtra">
                            <p className="small">*Referentie-inname van een gemiddelde volwassene (8400kj / 2000 kcal).
                                Jouw inname kan anders zijn door unieke factoren zoals je leeftijd, gewicht, lengte,
                                geslacht en beweegpatroon:</p>

                            <table className="nutritionfacts_table_small table table-borderless table-sm mb-0">
                                <thead>
                                <tr>
                                    <td colSpan="2"></td>
                                    <th>2,000</th>
                                    <th>2,500</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th colSpan="2">Vetten</th>
                                    <td>65g</td>
                                    <td>80g</td>
                                </tr>
                                <tr>
                                    <td className="blank-cell"></td>
                                    <th>Verzadigde vetzuren</th>
                                    <td>20g</td>
                                    <td>25g</td>
                                </tr>
                                <tr>
                                    <th colSpan="2">Cholesterol</th>
                                    <td>300mg</td>
                                    <td>300 mg</td>
                                </tr>
                                <tr>
                                    <th colSpan="2">Zout</th>
                                    <td>2,400mg</td>
                                    <td>2,400mg</td>
                                </tr>
                                <tr>
                                    <th colSpan="2">Koolhydraten</th>
                                    <td>300g</td>
                                    <td>375g</td>
                                </tr>
                                <tr>
                                    <td className="blank-cell"></td>
                                    <th>Vezels</th>
                                    <td>25g</td>
                                    <td>30g</td>
                                </tr>
                                </tbody>
                            </table>

                            <p className="small">Calorieën per gram:</p>
                            <p className="small text-center">
                                Vetten 9
                                &bull;
                                Koolhydraten 4
                                &bull;
                                Eitwitten 4
                            </p>
                        </div>
                    </div>
                )}

                {edamam()}

            </section>
        </div>
    )
}

/**
 * Export the NutritionTable constant
 */
export default NutritionTable

/**
 * Define the edamam logo
 * @returns {JSX.Element}
 */
const edamam = () => (
    <img src={edamamlogo} alt="Nutrition source"/>
)

/**
 * Define the vitamins constant
 * @returns {JSX.Element}
 */
const vitamins = () => {
    return (
        <table className="nutritionfacts_table_grid table table-borderless table-sm mb-0">
            <tbody>

            {
                // get vitamins table
                //$nutrition_table_vitamins = new Tableparser( $vitamins_values, $post->ID );
                //$nutrition_table_vitamins->getParsedTable( true );

                // get minerals table
                //$nutrition_table_minerals = new Tableparser( $minerals_values, $post->ID );
                //$nutrition_table_minerals->getParsedTable( true );
            }
            </tbody>
        </table>
    )
}