/**
 * Ingredients list for recipe pages
 */

// Import statements
import * as React from "react"
import {useState} from "react"

/**
 * Define the Ingredients constant
 * @param recipe
 * @returns {JSX.Element}
 * @constructor
 */
const Ingredients = ({recipe}) => {

    // ----------------------
    // BUILD TIME DATA FETCHING USING GRAPHQL
    // ----------------------


    // ----------------------
    // RUNTIME DATA FETCHING
    // ----------------------

    // get ingredients array
    let ingredients_array = [];
    if (recipe.ingredientsTextarea !== null) {
        ingredients_array = recipe.ingredientsTextarea.split("\n").map(e => e.trim()).filter(function (line) {
            if (line.split(",").map(e => e.trim()).join(",").replace(/,/g, '').length === 0) {
                return false; // skip
            }
            return true;
        })
    }


    // make the ingredients changeable
    const [state, setState] = useState(1)

    function changeIngredients(input) {
        setState(input);
    }

    // make the ingredients strikeable
    const [strikeState, setStrikeState] = useState([])

    /**
     * Function to strike through list items
     * @param strikeState
     * @param index
     */
    function addStrikeState(strikeState, index) {

        // define starting array
        let array = [];
        if (strikeState.length > 0) {
            array = strikeState;
        }

        // remove item from array when it already exists
        if (!array.includes(index)) {
            setStrikeState(array => [...array, index]);

            // add it to the array
        } else {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === index) {
                    array.splice(i, 1);
                }
            }
            setStrikeState(array => [...array]);
        }
    }

    // return the information
    return (
        <div className="row">
            <div className="order-1 col-sm-3 order-sm-2 col-md-4 col-lg-3">
                <div className="scale">
                    <span className="icon icon-resize-full"></span>Aanpassen porties
                    <div id="scale_button_wrapper">
                        <button onClick={() => changeIngredients(1)}
                                className={state === 1 ? "btn btn-tertiary btn-sm active" : "btn btn-tertiary btn-sm"}
                                id="scale1" data-scale="1" aria-label="Schalen naar originele porties.">1x
                        </button>
                        <button onClick={() => changeIngredients(2)}
                                className={state === 2 ? "btn btn-tertiary btn-sm active" : "btn btn-tertiary btn-sm"}
                                id="scale2" data-scale="2" aria-label="Schaal recept 2 keer.">2x
                        </button>
                        <button onClick={() => changeIngredients(3)}
                                className={state === 3 ? "btn btn-tertiary btn-sm active" : "btn btn-tertiary btn-sm"}
                                id="scale3" data-scale="3" aria-label="Schaal recept 3 keer.">3x
                        </button>
                    </div>
                </div>
                <div id="yield" className={state !== 1 ? "active" : ""} data-content="voor x personen">
                    <p id="serves_text">voor {state * parseFloat(recipe.servesText)} personen</p>
                    <p className="alert alert-warning" id="serves_disclaimer">Let op: ingrediënten zijn slechts
                        indicatief</p>
                </div>
            </div>
            <div className="order-2 col-sm-9 order-sm-1 col-md-8 col-lg-9">
                <h3>Ingrediënten</h3>
                {ingredients_array.map((line, index) => {
                    let ingredient = line.split(",");
                    return (
                        <button key={`ingredient-button-${index}`} onClick={() => {
                            addStrikeState(strikeState, index)
                        }} className={strikeState.includes(index) ? "active" : ""}>
                            {!isNaN(ingredient[0]) ? state * ingredient[0] : ingredient[0]}
                            {ingredient[1] ?? ''}
                            {ingredient[2] && <b>{ingredient[2]}</b>}
                            {ingredient[3] ?? ''}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

/**
 * Export the ingredients variable
 */
export default Ingredients